export const COLORS = [
	'gray',
	'red',
	'yellow',
	'green',
	'blue',
	'indigo',
	'purple',
	'pink',
] as const;
